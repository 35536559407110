
import './App.css';
import * as React from 'react';
import MfaNavBarNew from './components/MfaNavBarNew';
import { HomePage, AboutMFA, MissionAndVision, HowWeWork, ContactUs, PrayWithUs, AboutMFAAP, 
  GetInvolved,  OurMinistries,  PartneringChurch,  WhereisOurFocus, Footer,
  ChurchPlanting,   VillageTransformation,   LifeTransformation,   MercyMinistry,
  PgIndia, PgCambodia, PageMyanmar, PgNepal, PgSriLanka,
  MissionsPartner, MissionsTrip,
  ConnectWithUs, ConnectInPrayer, ConnectThroughGiving, ConnectWithStateTeam, OurPartners,
   MissionsTeamVic, MissionsTeamNsw, MissionTeamNz, MissionTeamLanka, FAQs,
   MissionTeamAus, MFAlaunch } from "./components/pages";
import { Routes, Route } from 'react-router-dom';





function App() {
  return (
          <div className='App'>
            <MfaNavBarNew />
            <Routes>
            
            <Route path="/MFAlaunch"/>
              <Route path="/" element={<HomePage />} />
              <Route path="/MissionAndVision" element={<MissionAndVision />} />
              <Route path="/aboutMFA" element={<AboutMFA />} />
              <Route path="/aboutMFAP" element={<AboutMFAAP />} />
              <Route path="/howWeWork" element={<HowWeWork />} />
              <Route path="/ourMinistries" element={<OurMinistries />} />
              <Route path="/whereFocus" element={<WhereisOurFocus />} />
              <Route path="/getInvolved" element={<GetInvolved />} />
              <Route path="/partneringChurch" element={<PartneringChurch />} />
              <Route path="/contactUs" element={<ContactUs />} />
              <Route path="/PrayWithUs" element={<PrayWithUs />} />
              <Route path="/ChurchPlanting" element={<ChurchPlanting />} />
              <Route path="/VillageTransformation" element={<VillageTransformation />} />
              <Route path="/LifeTransformation" element={<LifeTransformation />} />
              <Route path="/MercyMinistry" element={<MercyMinistry />} />
              <Route path="/PgIndia" element={<PgIndia />} />
              <Route path="/PgSriLanka" element={<PgSriLanka />} />
              <Route path="/PgNepal" element={<PgNepal />} />
              <Route path="/PageMyanmar" element={<PageMyanmar />} />
              <Route path="/PgCambodia" element={<PgCambodia />} />
              <Route path="/MissionsPartner" element={<MissionsPartner />} />
              
              <Route path="/OurPartners" element={<OurPartners />} />
              <Route path="/MissionsTrip" element={<MissionsTrip />} />
              
              <Route path="/MissionTeamAus" element={<MissionTeamAus />} />
              <Route path="/MissionsTeamVic" element={<MissionsTeamVic />} />
              <Route path="/MissionsTeamNsw" element={<MissionsTeamNsw />} />
              <Route path="/MissionTeamNz" element={<MissionTeamNz />} />
              <Route path="/MissionTeamLanka" element={<MissionTeamLanka />} />
              <Route path="/involve" element={<ConnectWithUs />} />
              <Route path="/ConnectInPrayer" element={<ConnectInPrayer />} />
              <Route path="/ConnectThroughGiving" element={<ConnectThroughGiving />} />
              <Route path="/ConnectWithStateTeam" element={<ConnectWithStateTeam />} />
              <Route path="/FAQs" element={<FAQs />} />
            </Routes>
            
            <Footer></Footer>
            
          </div>
              
                
                  
               
           
          
  );
}

export default App;
