import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import TourIcon from '@mui/icons-material/Tour';
import Groups2Icon from '@mui/icons-material/Groups2';
import { tile_ChurchPlanting , tile_LifeTransformation,
    tile_MercyMinistry, tile_VillageTransformation
} from "C:/react/mfa/src/img";


export const MenuItems = [
    {
        mid: 'aboutMFA',
        mpath: '/aboutMFA',
        mtitle: 'About Us',
        mshortdesc: 'Missions Fellowship Australia is a trans-denominational mission organization based in New South Wales, Australia. MFA is a charitable non profit organisation and registered with the Office of Fair Trading in New South Wales as an incorporated association.',
        mitems: [ 
            {
                mtext:'Mission & Vision',
                path: '/MissionAndVision'
            },
            {    
                mtext:'How We Work?',
                path: '/howWeWork'
            },
            {    
                mtext:'Missions Fellowship Australia',
                path: '/aboutMFA'
            },
            {    
                mtext:'Missions Fellowship Asia Pacific',
                path: '/aboutMFAP'
            }
        ]
    },
    {
        mid: 'ourMinistries',
        mpath: '/ourMinistries',
        mtitle: 'Our Ministries',
        mshortdesc: 'Your support will help to equip Southeast Asian mission projects, helping affected communities with basic needs and providing basic trade skills and education through evangelism.',
        mitems: [ 
            {
                mtextTitle: 'Sponsor A Missionary',
                path: '/ChurchPlanting',
                pic: tile_ChurchPlanting,
                desc: 'With the help of our sponsors, MFA is able to build small Church buildings in some of these villages.  MFA has provided assistance to purchase bicycles, and in some instances motor-cycles, for missionaries who are without any transport.',
                shortdesc: 'Sponsor a native missionary',
                mtext: 'Church Planting',
                link: ''
            },
            {    
                mtextTitle: 'Sponsor A Child',
                path: '/VillageTransformation',
                pic: tile_VillageTransformation,
                desc: 'Transform destitute communities by empowering children physically, intellectually, and spiritually through a Christ centred learning system. MFA is currently facilitating sponsorship of nearly 500 children through various mission organisations in India, Nepal and Sri Lanka and considering expansion to Myanmar',
                shortdesc: 'Sponsor a child at risk',
                mtext: 'Village Transformation',
                link: ''
            },
            {    
                mtextTitle: 'Sponsor A Young Adult',
                path: '/LifeTransformation',
                pic: tile_LifeTransformation,
                desc: 'Save the lives of young adults from the cycle of poverty and hunger by equipping them with  vocational skill training through a Christ centered learning system.  For young adults (16 years and above).',
                shortdesc: 'Support a young adult',
                mtext: 'Life Transformation',
                link: ''
            },
            {    
                mtextTitle: 'Sponsor  A Benevolent Project',
                path: '/MercyMinistry',
                pic: tile_MercyMinistry,
                desc: 'Focuses on giving people practical love and support. From microfinancing self sustainable income generation projects to higher education. It allows us to invest in the lives of others, seeking to serve them. MFA is currently supporting livelihood projects in Sri Lanka with a vision of expanding to other South Asian countries.',
                shortdesc: 'Support a benevolent relief project',
                mtext: 'Mercy Ministry',
                link: ''
            }
        ]
    },
    {
        mid: 'whereFocus',
        mpath: '/whereFocus',
        mtitle: 'Our Focus',
        mshortdesc: "The Vision of MFA is to reach the Southeast Asian countries to spread the gospel of Jesus Christ by equipping missionaries to serve effectively in their own community. Whether you already have a heart for a people or a place, or if you have no idea where to start, we'd love to hear from you.",
        mitems: [ 
            {    
                mtext:'Cambodia',
                path: '/PgCambodia'
            },
            {
                mtext:'India',
                path: '/PgIndia'
            },
            {    
                mtext:'Myanmer',
                path: '/PageMyanmar'
            },
            {    
                mtext:'Nepal',
                path: '/PgNepal'
            },
            {    
                mtext:'Sri Lanka',
                path: '/PgSriLanka'
            },
        ]
    },
    {
        mid: 'getInvolved',
        mpath: '/getInvolved',
        mtitle: 'Get Involved',
        mshortdesc: 'You can connect with MFA as per God’s calling and purpose. Every journey starts with the first step, but God does not expect you to begin on your own. We’re keen to help by walking with you, encouraging, supporting and praying with you, as you seek His will for your part in missions project.',
        mitems: [ 
            {    
                mtext:'Connect with Us',
                path: '/involve',
                icon: <ContactPageIcon />
            },
            {
                mtext:'Connect in Prayer',
                path: '/ConnectInPrayer',
                icon: <PeopleAltIcon />
            },
            {    
                mtext:'Connect through Giving',
                path: '/ConnectThroughGiving',
                icon: <VolunteerActivismIcon />
            },
            {    
                mtext:'Connect with Missions Tour',
                path: '/MissionsTrip',
                icon: <TourIcon />
            },
            {    
                mtext:'Connect with State Missions Team',
                path: '/ConnectWithStateTeam',
                icon: <Groups2Icon />
            }
        ]
    },
    {
        mid: 'partneringChurch',
        mpath: '/partneringChurch',
        mtitle: 'Organisation Connect',
        mshortdesc: 'Does your church or organisation partner with MFA? Find ideas here for how your church can better pray, care, and give in support of missionaries. One of the best ways for your church to be involved with MFA is by supporting a specific missionary (or more than one)! If you want to support a project and would like to inquire about one, please contact your local state Missions Team for ideas on how to best support our projects.',
        mitems: [ 
            {
                mtext:'Become a Missions Partner',
                path: '/MissionsPartner'
            }, 
            {    
                mtext:'Our Partner Organisations',
                path: '/OurPartners'
                
            },
            {    
                mtext:'Be a Prayer Partner',
                path: '/ConnectInPrayer'
            }, 
            {    
                mtext:'Go on a Missions Tour',
                path: '/MissionsTrip'
                
            },
        ]
    },
    {
        mid: 'contactUs',
        mpath: '/contactUs',
        mtitle: 'Contact Us',
        mshortdesc: 'Have a question about MFA, serving in local missions team, or supporting missions project?  Need to connect to one of our ministry team? We would love to help you, so just send a message or give us a call.',
        mitems: [ 
            {
                mtext:'Australia',
                path: '/MissionTeamAus'
            },            
            {
                mtext:'New Zealand',
                path: '/MissionTeamNz'
            },            
            {
                mtext:'MFAP Lanka',
                path: '/MissionTeamLanka'
            },
            {
                mtext:'FAQs',
                path: '/FAQs'
            }

        ]
    }
];
    
        
                
