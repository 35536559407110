import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Grid } from '@mui/material';
import Fab from '@mui/material/Fab';
import YouTubeIcon from '@mui/icons-material/YouTube';
import FacebookTwoToneIcon from '@mui/icons-material/FacebookTwoTone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import ChurchIcon from '@mui/icons-material/Church';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import ManIcon from '@mui/icons-material/Man';
import FavoriteIcon from '@mui/icons-material/Favorite';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import GroupIcon from '@mui/icons-material/Group';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import TourIcon from '@mui/icons-material/Tour';
import AssessmentIcon from '@mui/icons-material/Assessment';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import HandshakeIcon from '@mui/icons-material/Handshake';

import ScrollTopButton from '../ScrollTopButton'
import InstagramIcon from '@mui/icons-material/Instagram';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LanguageIcon from '@mui/icons-material/Language';
import HelpIcon from '@mui/icons-material/Help';
import InboxIcon from '@mui/icons-material/Inbox';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { green, red } from '@mui/material/colors';
import { styled } from '@mui/material/styles';

function Copyright() {
    return (
      <Typography variant="body2" color="white" align="center">
        {'Copyright © '}
        
          Missions Fellowship Website
        {' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }
  const GradientInstagramIcon = () => (
    <>
      <svg width={0} height={0}>
        <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
          <stop offset={0} stopColor="rgba(241,184,74,1)" />
          <stop offset={1} stopColor="rgba(207,113,8,1)" />
        </linearGradient>
      </svg>
      <InstagramIcon sx={{ fill: "url(#linearColors)" }} />
    </>
  )

  const FooterButton = styled(Button)(({ theme }) => ({
    color: 'white',
    padding: '4px 5px',
    fontSize: '0.8rem',
    backgroundColor: 'transparent',
    border: 'none',
    '&:hover': {
      fontWeight:'Bold', color:'white', borderBlockColor:'white',
        border: '1px solid white',
    },
  }));

function Footer(){
    const navigate = useNavigate()
    return(
            
            <Box sx={{ bgcolor: '#008ca8', height: 'auto', p: 1 }} component="footer" className="mfacolor_newcolor">
            <ScrollTopButton />
            <Grid container>
                <Grid item xs={12} sx={{ align: 'center'}}>
                    <Typography variant="h5" align="center" gutterBottom color="white">
                        Missions Fellowship Australia
                    </Typography>
                    <Typography variant="body1" align="center" gutterBottom color="white">
                        follow us on
                    </Typography>
                    <Box sx={{  display: 'flex', alignItems: 'center' , justifyContent: 'center',  p: 1, '& > :not(style)': { m: 1 }  }}>
                        <Fab size="medium" sx={{ color: 'skyblue' }} aria-label="facebook"
                            href='https://www.facebook.com/profile.php?id=100064745646823&mibextid=ZbWKwL'>
                            <FacebookTwoToneIcon fontSize="large"/>
                        </Fab>
                        <Fab size="medium" sx={{ color: green[500] }} aria-label="twitter"
                            href='https://x.com/missionsmfa?t=Cd8Ad8wUaxyHwAwq1hJ1Dg&s=09'>
                            <TwitterIcon fontSize="large"/>
                        </Fab>
                        <Fab size="medium" sx={{ color: red[500] }} aria-label="youtube"
                            href='https://youtube.com/@missionsfellowshipaustraliamfa?si=IkDQzuGBGbbKRY2W'>
                            <YouTubeIcon fontSize="large"/>
                        </Fab>
                        <Fab size="medium"  aria-label="instagram"
                            href='https://www.instagram.com/missionsfellowshipaustralia/profilecard/?igsh=MWYwZ3dnaGJ5bTIwNQ=='>
                            <GradientInstagramIcon fontSize="large"/>
                        </Fab>
                    </Box>
                
                    
                    
                </Grid>
            </Grid>
            <Grid container spacing={0} sx={{ flexGrow: 1 ,justifyContent:'center', }}>
                <Grid item md={1}></Grid>
                <Grid item md={3} sx={{ align: 'center',p:2}}>
                    <Typography variant="body1" align="left" gutterBottom color="white"
                    sx={{ p:0.5}}>
                        Ministries
                    </Typography>
                    <Typography variant="body2" align="left" gutterBottom color="white">
                        <FooterButton startIcon={<ChurchIcon/>} onClick={() => {
                            navigate('/ChurchPlanting')
                        }}>Church Planting</FooterButton>
                    </Typography>
                    <Typography variant="body2" align="left" gutterBottom color="white">
                        <FooterButton startIcon={<HolidayVillageIcon/>} onClick={() => {
                            navigate('/VillageTransformation')
                        }}>Village Transformation</FooterButton>
                    </Typography>
                    <Typography variant="body2" align="left" gutterBottom color="white">
                        <FooterButton startIcon={<ManIcon/>} onClick={() => {
                            navigate('/LifeTransformation')
                        }}>Life Transformation</FooterButton>
                    </Typography>
                    <Typography variant="body2" align="left" gutterBottom color="white">
                        <FooterButton startIcon={<FavoriteIcon/>} onClick={() => {
                            navigate('/MercyMinistry')
                        }}>Mercy Ministry</FooterButton>
                    </Typography>
                    
                </Grid>
                
                <Grid item md={2} sx={{ align: 'center',p:2}}>
                    <Typography variant="body1" align="left" gutterBottom color="white"
                    sx={{ p:0.5}}>
                        Quick Links
                    </Typography>
                    <Typography variant="body2" align="left" gutterBottom color="white">
                    <FooterButton startIcon={<PersonAddIcon/>} onClick={() => {
                            navigate('/involve')
                        }}>Get Involved</FooterButton>
                    </Typography>
                    <Typography variant="body2" align="left" gutterBottom color="white">
                        <FooterButton startIcon={<HandshakeIcon/>} onClick={() => {
                            navigate('/MissionsPartner')
                        }}>Missions Partner</FooterButton>
                    </Typography>
                    <Typography variant="body2" align="left" gutterBottom color="white">
                        <FooterButton startIcon={<TourIcon/>} onClick={() => {
                            navigate('/MissionsTrip')
                        }}>Missions Trip</FooterButton>
                    </Typography>
                    <Typography variant="body2" align="left" gutterBottom color="white">
                        <FooterButton startIcon={<AssessmentIcon/>} onClick={() => {
                            navigate('')
                        }}><Link to="route" target="_blank" rel="noopener noreferrer" underline="none"
                href="https://www.canva.com/design/DAGV4meqKTM/Xl7HeI_pvVXD-MxjX7963w/view?utm_content=DAGV4meqKTM&utm_campaign=designshare&utm_medium=link&utm_source=editor" sx={{ color: 'inherit'}}
            >Annual Report</Link>
                        </FooterButton>
                    </Typography>
                    
                </Grid>
                <Grid item md={2}sx={{ align: 'center',p:2}}>
                    <Typography variant="body1" align="left" gutterBottom color="white"
                    sx={{ p:0.5}}>
                        Connect
                    </Typography>
                    <Typography variant="body2" align="left" gutterBottom color="white">
                        <FooterButton startIcon={<GroupIcon/>}  onClick={() => {
                            navigate('/ConnectInPrayer')
                        }}>Prayer</FooterButton>
                    </Typography>
                    <Typography variant="body2" align="left" gutterBottom color="white">
                        <FooterButton startIcon={<VolunteerActivismIcon/>} onClick={() => {
                            navigate('/ConnectThroughGiving')
                        }}>Giving</FooterButton>
                    </Typography>
                    
                    <Typography variant="body2" align="left" gutterBottom color="white">
                        <FooterButton startIcon={<ContactPageIcon/>} onClick={() => {
                            navigate('/MissionTeamAus')
                        }}>Contact Us</FooterButton>
                    </Typography>
                    
                </Grid>
                <Grid item md={2} sx={{ align: 'center',p:2}}>
                    <Typography variant="body1" align="left" gutterBottom color="white"
                    sx={{ p:0.5}}>
                        Others
                    </Typography>
                    <Typography variant="body2" align="left" gutterBottom color="white">
                        <FooterButton startIcon={<LanguageIcon/>} onClick={() => {
                            navigate('')
                        }}>Events </FooterButton>
                    </Typography>
                    <Typography variant="body2" align="left" gutterBottom color="white">
                        <FooterButton startIcon={<HelpIcon/>} onClick={() => {
                            navigate('/FAQs')
                        }}>FAQs </FooterButton>
                    </Typography>
                    <Typography variant="body2" align="left" gutterBottom color="white">
                    <FooterButton startIcon={<MailOutlineIcon/>} onClick={() => {
                            navigate('')
                        }}>Email</FooterButton>
                    </Typography>
                    <Typography variant="body2" align="left" gutterBottom color="white">
                    <FooterButton startIcon={<InboxIcon/>} onClick={() => {
                            navigate('')
                        }}>
                            <Link to="route" target="_blank" rel="noopener noreferrer" underline="none"
                href="https://mbox.s452.sureserver.com/?domain=mfa.org.au" sx={{ color: 'inherit'}}
            >Webmail</Link></FooterButton>
                    </Typography>
                    
                </Grid>
            </Grid>
            <Grid container sx={{ height: '10vh', p: 1 }} c>

            </Grid>
            <Grid container>
                <Grid item xs={12} sx={{ align: 'center'}}> 
                    <Copyright />
                </Grid>
            </Grid>
            
            
            
            </Box>
    
    );
}

export default Footer;